import React from 'react';

const TwitterTimeline = () => {
    return(
        <div id="twitter">
            <a href="https://twitter.com/israpdead?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-size="large" data-dnt="true" data-show-count="false">Follow @israpdead</a>
        </div>
    )
}

export default TwitterTimeline;
