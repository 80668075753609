import React from 'react';

const Discord = () => {
    return(
        <div id="discord">
            <iframe src="https://discord.com/widget?id=1143575154153242774&theme=dark" width="100%" height="100%" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
        </div>
    )
}

export default Discord;
