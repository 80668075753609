export const Today = () => {
    return (
        <div id="today">
            <p>
                <b>listen to this today:</b><br /><br />
                niontay <br />dante's inferno <br /><a id="cv" href="https://10k.ffm.to/dontaysinferno" target="_blank">&#128266;</a><br /><br />
                babyface ray<br />summer's mine <br /><a id="cv" href="https://music.empi.re/summersmine" target="_blank">&#128266;</a><br /><br />
                heembeezy<br />really heem vol 1 <br /><a id="cv" href="https://www.youtube.com/watch?v=vzwjUubucy4&list=OLAK5uy_lPOLbKgcc5MasD1OgwnSz6U-lRGnhZtnY" target="_blank">&#128266;</a><br /><br />
                icytwat<br />final boss <br /><a id="cv" href="https://lnk.to/FINALBOSSMUSIC" target="_blank">&#128266;</a>
            </p>
        </div>
    )
}

export const ArtistSpotlight = () => {
    return (
        <div id="artistSpotlight">
            <p>
                <b>&#129351;&#127908;&#128197;<br /><br />
                anycia</b>
                <br /><br />
                kimora lee <br /><a id="cv" href="https://www.youtube.com/watch?v=8-h7JDov4sI" target="_blank">&#128266;</a><br /><br />
                makin em' dance <br /><a id="cv" href="https://www.youtube.com/watch?v=RXa_9Jj7qf8" target="_blank">&#128266;</a><br /><br />
                so what <br /><a id="cv" href="https://www.youtube.com/watch?v=a4pChTOfjSk" target="_blank">&#128266;</a>
            </p>
        </div>
    )
}

